import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import 'moment-timezone';
import { Button, Divider, FormControlLabel, FormControl, FormLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import LayoutContainer from "./layout/LayoutContainer";
import LayoutItem from "./layout/LayoutItem";
import Timer from './shared/Timer';
import Header from "./shared/Header";
import ReservationInfo from "./shared/ReservationInfo";
import "./../assets/scss/Payment.scss";
import {defaultLang} from "../features/store/storeSlice";

export default function Payment(props) {
  const [discountTotal, setDiscountTotal] = useState(null);
  const { currency, orders, reservation, theme, time, userData, venueDetails } = useSelector((state) => state.store);
  const { t } = useTranslation();

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : "",
  };

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const [showPromocode, setShowPromocode] = useState(false);
  const [discountActive, setDiscountActive] = useState(false);
  const [toggleChanged, setToggleChanged] = useState(false);
  const [form, setForm] = useState({
    method: null,
    promocode: null,
  });

  var [total, setTotal] = useState(0);
  const promocode = { name: "testpromocode", discount: 50 };
  const history = useHistory();

  useEffect(() => {
    orders.forEach((value) => {
      calculateTotal();
    });
  }, []);

  function calculateTotal() {
    orders.forEach((value) => {
      let tot = total + value.price * value.seats;
      setTotal(tot);
    });
  }

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  function promocodeToggle() {
    if (showPromocode) {
      setDiscountTotal(0);
      setShowPromocode(false);
      setForm({ ...form, [promocode]: null });
      setDiscountActive(false);
      setTotal(total + discountTotal);
    }
    setShowPromocode(!showPromocode);
  }

  function applyPromocode() {
    if (form.promocode === promocode.name) {
      if (promocode.discount) {
        let discount = promocode.discount / 100;
        let dis = total * discount;
        setDiscountTotal(dis);
        setTotal(total - dis);
        setDiscountActive(true);
      }
    }
  }

  function submit() {
    history.push("/success");
  }

  function promoCode() {
    if (showPromocode) {
      if (discountActive) {
        return (
          <LayoutItem xs={10} className="promocodeActive">
            <LayoutContainer
              spacing={1}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <LayoutItem xs={8} className="orderName">
                {t("promocode_discount") + " " + promocode.discount + "%"}
                <CancelIcon onClick={promocodeToggle} />
              </LayoutItem>
              <LayoutItem xs={4} className="orderPrice">
                - {currency} {discountTotal}
              </LayoutItem>
            </LayoutContainer>
          </LayoutItem>
        );
      }
      return (
        <LayoutItem xs={10} className="promocode">
          <LayoutContainer spacing={1} alignItems="flex-start" justifyContent="flex-start">
            <LayoutItem xs={11} className="promocode">
              <TextField
                id="outlined-basic"
                fullWidth
                onChange={handleChange}
                name="promocode"
                label={t("promocode")}
                variant="outlined"
              />
              <Button color="primary" onClick={applyPromocode}>
                {t("apply")}
              </Button>
            </LayoutItem>
            <LayoutItem xs={1}>
              <CancelIcon onClick={promocodeToggle} />
            </LayoutItem>
          </LayoutContainer>
        </LayoutItem>
      );
    } else {
      return (
        <LayoutItem xs={9} className="promocode">
          <Button color="primary" onClick={promocodeToggle}>
            {t("promocode")}?
          </Button>
        </LayoutItem>
      );
    }
  }

  function timeFormatter(time) {
    let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
    let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if (is_dst) {
    //   offset += 60;
    // }
    return moment(time, 'HH:mm').add(-offset, 'minutes').format('HH:mm')
  }

  return (
    <LayoutContainer
      alignItems="center"
      justifyContent="center"
      className="paymentContainer"
      style={widgetBackgroundColor}
    >
      <LayoutItem xs={12}>
        <Header title={theme.headerTitle && theme.headerTitleValue && defaultLang==='en' ? theme.headerTitleValue : ''} />
      </LayoutItem>
      <LayoutItem xs={10}>
        <ReservationInfo
          name={userData.firstName + ' ' + userData.lastName}
          date={moment(reservation.date).format('ddd DD MMM YYYY')}
          time={timeFormatter(reservation.time.time)}
          separator={t('at')}
          persons={reservation.tableFor}
        />
        <Divider />
      </LayoutItem>
      <LayoutItem xs={10}>
        <FormControl component="fieldset" className="paymentMethodContainer">
          <FormLabel component="legend" className="radioPaymentLabel">
            {t("payment_method")}
          </FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              value="cash_at_the_door"
              control={
                <Radio name="method" onChange={handleChange} color="primary" />
              }
              label={t("cash_at_the_door")}
            />
            <FormControlLabel
              value="online_payment"
              control={
                <Radio name="method" onChange={handleChange} color="primary" />
              }
              label={t("online_payment")}
            />
          </RadioGroup>
        </FormControl>
      </LayoutItem>
      <LayoutItem xs={10}>
        <Divider />
      </LayoutItem>
      <LayoutItem xs={10}>
        <Typography
          variant="h5"
          display="block"
          align="left"
          className="sectionTitle"
        >
          {t("order_summary")}
        </Typography>
      </LayoutItem>
      {orders.map((value, index) => {
        return (
          <LayoutItem xs={10} key={index}>
            <LayoutContainer
              spacing={1}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <LayoutItem xs={8} className="orderName">
                {value.name} {value.seats > 1 ? "x " + value.seats : ""}
              </LayoutItem>
              <LayoutItem xs={4} className="orderPrice">
                {value.currency} {value.price * value.seats}
              </LayoutItem>
            </LayoutContainer>
          </LayoutItem>
        );
      })}
      {promoCode()}
      <LayoutItem xs={10}>
        <LayoutContainer
          spacing={1}
          alignItems="flex-start"
          justifyContent="flex-start"
          className="total"
        >
          <LayoutItem xs={8} className="orderName">
            {t("total")}
          </LayoutItem>
          <LayoutItem xs={4} className="orderPrice">
            {currency} {total}
          </LayoutItem>
        </LayoutContainer>
      </LayoutItem>
      <LayoutItem xs={10}>
        <Button variant="contained" onClick={submit} fullWidth color="primary" style={buttonDesign}>
          {t('proceed')}
        </Button>
      </LayoutItem>
      <LayoutItem xs={10}>
        <div className="inlineLabel">
          {t("cancellation_policy")}
        </div>
      </LayoutItem>
      <LayoutItem xs={10}>
        <div className="cancellationPolicy">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</div>
      </LayoutItem>
      <LayoutItem xs={10}>
        <Timer hoursMinSecs={time} />
      </LayoutItem>
    </LayoutContainer>
  );
}
