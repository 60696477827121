import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import 'moment-timezone';
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import LayoutItem from '../layout/LayoutItem';
import '../../assets/scss/GuestReservationDetail.scss';

export default function GuestReservationDetail({ reservation }) {
    const { t } = useTranslation();
    const { currency, defaultLang, theme } = useSelector((state) => state.store);

    const style = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
    };

    const numberFormat = (number, decimals = 0, decPoint = '.', thousandsSep = '') => {
        const multiplier = Math.pow(10, decimals);
        const roundedNumber = Math.round(number * multiplier) / multiplier;
        const parts = roundedNumber.toFixed(decimals).split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
        return parts.join(decPoint);
    }

    const calculateCancellationFee = () => {
        let now = moment(moment().format('YYYY-MM-DD HH:mm'));
        let date = moment(reservation.reservation_date).format('YYYY-MM-DD');
        let time = moment(reservation.reservation_date + ' ' + reservation.reservation_time).format('HH:mm');
        let reservationDateTime = moment(moment(date + ' ' + time).format('YYYY-MM-DD HH:mm'));
        let cancellationFee = 0;
        if (reservation.reservation_no_show_cancellation_info && reservation.reservation_no_show_cancellation_info.cancellation_fee && reservation.reservation_no_show_cancellation_info.cancellation_time_limit >= reservationDateTime.diff(now, 'minutes')) {
            cancellationFee = numberFormat(reservation.reservation_no_show_cancellation_info.cancellation_fee * reservation.guest_count, 2);
        }
        return currency + ' ' + cancellationFee;
    }

    return (
        <Box component={'div'} className='guestReservationDetailContainer'>
            <Box className='guestTitle'>
                <Typography variant="h2" style={style}>{t("hello")} {reservation && reservation.reservation_name && reservation.reservation_name.split(" ")[0]}</Typography>
                <Typography variant="p" style={style}>{t("we_are_sad_to_see_you_go")}</Typography>
            </Box>
            <LayoutItem xs={12}>
                <Divider />
            </LayoutItem>
            <TableContainer >
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell style={style}>{t("reference")}</TableCell>
                            <TableCell style={style} className='uppercase'>#{reservation.reservation_code}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={style}>{t("name")}</TableCell>
                            <TableCell style={style}>{reservation.reservation_name}</TableCell>
                        </TableRow>
                        {reservation.email &&
                            <TableRow>
                                <TableCell style={style}>{t("email")}</TableCell>
                                <TableCell style={style}>{reservation.email}</TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell style={style}>{t("mobile")}</TableCell>
                            <TableCell style={style}>{reservation.mobile_number}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={style}>{t("party_size")}</TableCell>
                            <TableCell style={style}>{reservation.guest_count} {t("guest's")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={style}>{t("date_&_time")}</TableCell>
                            <TableCell style={style}>{moment(reservation.reservation_date).locale(defaultLang).format('ddd, DD MMM, YYYY')} {t('at')} {reservation.reservation_time}</TableCell>
                        </TableRow>
                        {reservation.payment_type && reservation.payment_type === 'preauth' &&
                            <TableRow>
                                <TableCell style={style}>{t("cancellation_fee")}</TableCell>
                                <TableCell style={style}>{reservation.reservation_no_show_cancellation_info && reservation.reservation_no_show_cancellation_info.cancellation_fee ? ` ${calculateCancellationFee()}` : t('no_additional_fee')}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <LayoutItem xs={12}>
                <Divider />
            </LayoutItem>
        </Box>
    );
}