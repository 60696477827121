import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import 'moment-timezone';
import TagManager from 'react-gtm-module';
import "../assets/scss/Login.scss";
import { Button, Checkbox, Divider, FormControl, FormControlLabel, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Email, Person } from "@material-ui/icons";
import { ReactComponent as Birthday } from "./../assets/svg/birthday.svg";
import { decrementLoader, incrementLoader, updateNote, updateTagNote, updateUserData, updateProperty } from "./../features/store/storeSlice";
import { createReservation, joinWaitlist } from "./../features/store/api";
import ReservationInfo from "./shared/ReservationInfo";
import LayoutContainer from "./layout/LayoutContainer";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LayoutItem from "./layout/LayoutItem";
import PhoneNumber from "./PhoneNumber";
import Header from "./shared/Header";
import FeedbackForm from "../components/shared/FeedbackForm";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Copyright from "./shared/Copyright";
// import reCAPTCHA from "react-google-recaptcha"
import ReCAPTCHA from "react-google-recaptcha";
import ReactPixel from "react-facebook-pixel";

export default function Login(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const recaptchaRef = React.useRef();
  let type = props.location.type ? props.location.type : 'normal';
  let waitlistTime = props.location.waitlistTime ? props.location.waitlistTime : '';
  const dispatch = useDispatch();
  const { theme, previewMode, tagsIds, defaultLang, reservation, sections, venueDetails,selectedPaymentGroup,selectedEvent } = useSelector((state) => state.store);
  const queryParams = new URLSearchParams(window.location.search);

  const customPadStart = (day) => {
    if (day <= 9) {
      return '0' + day;
    }
    return day;
  }

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    temporaryLocalNumber: false,
    termsConditionAgree: false,
    newsletter: false,
    dateOfDay: '',
    dateOfMonth: ''
  });
  const [queryString, setQueryString] = useState('');
  const [guestCountError, setGuestCountError] = useState(false);

  const [endOfMonth, setEndOfMonth] = useState(31);
  const [days, setDays] = useState(Array.from({ length: endOfMonth }, (v, k) => customPadStart(k + 1)));
  const [months] = useState([
    { 'name': 'January', 'value': '01' },
    { 'name': 'February', 'value': '02' },
    { 'name': 'March', 'value': '03' },
    { 'name': 'April', 'value': '04' },
    { 'name': 'May', 'value': '05' },
    { 'name': 'June', 'value': '06' },
    { 'name': 'July', 'value': '07' },
    { 'name': 'August', 'value': '08' },
    { 'name': 'September', 'value': '09' },
    { 'name': 'October', 'value': '10' },
    { 'name': 'November', 'value': '11' },
    { 'name': 'December', 'value': '12' }
  ]);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [captchaError, setCaptchaError] = useState(false);

  const query = new URLSearchParams(props.location.search);
  const oid = query.get("oid");

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const styleSmallText = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    opacity: 0.5
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
  };

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };
  
  function number_format(number, decimals = 0, decPoint = '.', thousandsSep = '') {
    const multiplier = Math.pow(10, decimals);
    const roundedNumber = Math.round(number * multiplier) / multiplier;
    const parts = roundedNumber.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
    return parts.join(decPoint);
  }



  function calculatePackageAmount(price){
    let amount=JSON.parse(JSON.stringify(price));
    let vat_percentage = venueDetails.vat_percentage;
    let isVatIncluded=reservation.section.payment_group ? reservation.section.payment_group.is_vat_included : false;
    if(selectedEvent.event_id){
      isVatIncluded=selectedEvent.payment_group ? selectedEvent.payment_group.is_vat_included : false;
    }
    if(!isVatIncluded && vat_percentage && (vat_percentage !== '' || vat_percentage !== 0)){
      amount = amount * (1 + parseInt(vat_percentage) / 100);
      return number_format(amount, 2);
    }
    return amount;
  }

  const onSubmit = async (values) => {
    console.log(values, reservation);
    if(reservation.tableFor){
    setForm(values);
    dispatch(updateUserData({ key: "firstName", value: values.firstName }));
    dispatch(updateUserData({ key: "lastName", value: values.lastName }));
    dispatch(incrementLoader());

    let query = new URLSearchParams(props.location.search);
    let oid = query.get("oid");
    let time = reservation.time.time ? reservation.time.time : reservation.time.start_time;
   // let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
   // let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if (is_dst) {
    //   offset += 60;
    // }

    let offset = moment.tz
    .zone(venueDetails.timezone)
    .utcOffset(moment(reservation.date + "T" + time));

    let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if (is_dst) {
    //   offset += 60;
    // }

    let dateTime = moment(reservation.date + 'T' + time).add(-offset, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    dateTime = moment(reservation.date + "T" + moment(dateTime).format('HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss');
    let storedDateTime = dateTime;
    if ('00:00' <= moment(dateTime).format('HH:mm') && moment(dateTime).format('HH:mm') < '06:00') {
      dateTime = moment(dateTime).add('1', 'd').format('YYYY-MM-DD HH:mm:ss')
    }
    let allergies = '';
    if (reservation.preferredTable) {
      allergies = 'Preferred section: ' + reservation.preferredTable + ' ';
      if(reservation.preferredTable==='Any section'  && !reservation.section.allow_guests_to_select_section && reservation.section.allow_any_section !== 1){
        allergies = ' ';
      }
    }
    if (reservation.allergies) {
      allergies = allergies + 'Allergies: ' + reservation.allergies + '.';
    }
    if (reservation.occasion) {
      allergies = allergies + ' Occassion: ' + reservation.occasion + '. '
    }
    if (reservation.dietary) {
      allergies = allergies + ' Dietary Preferences: ' + reservation.dietary + '. '
    }
    if (reservation.widget) {
      allergies = allergies + ' Additional Request: ' + reservation.widget + '. '
    }
    let tagIds = [];
    let guestTagsIds = [];
    if (tagsIds.allergies.length) {
      guestTagsIds = [...guestTagsIds, ...tagsIds.allergies];
    }
    if (tagsIds.dietary.length) {
      guestTagsIds = [...guestTagsIds, ...tagsIds.dietary];
    }
    if (tagsIds.widget.length) {
      tagIds = [...tagIds, ...tagsIds.widget];
    }
    if (tagsIds.occasion.length) {
      tagIds = [...tagIds, ...tagsIds.occasion];
    }

    let obj = {
      reservation_datetime: dateTime,
      first_name: values.firstName,
      last_name: values.lastName,
      outlet_id: oid,
      guest_tags_ids: guestTagsIds,
      tag_ids: tagIds,
      reservation_note: allergies ? allergies : '',
      country_id: reservation.country_id,
      guest_count: reservation.tableFor,
      email: values.emailAddress,
      phone_number: values.phoneNumber,
      newsletter_subscription: form.newsletter ? 1 : 0,
      reservation_source: '',
      section_id: reservation.section_id,
      shift_id: reservation.section.shift_id,
      //token:values.captcha
    };

    if(reservation.duration) obj.block_hour = reservation.duration;
  
    if(theme.captchaActive){
      obj.token = values.captcha
    }
    if(selectedEvent.event_id){
      obj.event_id=selectedEvent.event_id;
    }
    if (queryParams.get('source')) {
      obj.reservation_source = queryParams.get('source')
    }
    if (queryParams.get('utm_id')) {
      obj.utm_id = queryParams.get('utm_id');
    }
    if (queryParams.get('utm_source')) {
      obj.utm_source = queryParams.get('utm_source');
    }
    if (queryParams.get('utm_medium')) {
      obj.utm_medium = queryParams.get('utm_medium');
    }
    if (queryParams.get('utm_campaign')) {
      obj.utm_campaign = queryParams.get('utm_campaign');
    }
    if (queryParams.get('utm_term')) {
      obj.utm_term = queryParams.get('utm_term');
    }
    if (queryParams.get('utm_content')) {
      obj.utm_content = queryParams.get('utm_content');
    }
    if (values.birthDay && values.birthMonth) {
      obj.date_of_birth = values.birthMonth.value + '-' + values.birthDay;
    }
    if (reservation.selectedCategories.length) {
      let categories = [];
      reservation.selectedCategories.forEach((item) => {
        let cat = {
          name: item.package_name,
          count: item.count ? parseInt(item.count) : reservation.tableFor,
          booking_per:item.booking_per,
          package_amount:calculatePackageAmount(item.package_amount),
          max_guest_count:item.max_guest_count,
          id:item.id
        };
        if (item.amount)
          cat.amount = parseInt(item.amount);
        categories.push(cat);
      })
      if (categories.length)
        obj.visitors_breakdown = JSON.stringify(categories);
    }

    if (reservation.duration && reservation.duration !== '') {
      obj.block_hour_duration = reservation.duration;
    }
    var qs = require('qs');

    let gtmEvent;
    if (theme.googleTagsManager && theme.googleTagsManagerId) {
      gtmEvent = {
        'event': 'servme_reservation_added',
        'reservation_data': obj.guest_count + ' people, ' + moment(obj.reservation_datetime).format('YYYY-MM-DD HH:mm') + '. Notes: ' + obj.reservation_note,
        // 'reservation_booking_datetime': moment().format('YYYY-MM-DD HH:mm'),
        // 'reservation_datetime': moment(obj.reservation_datetime).format('YYYY-MM-DD HH:mm'),
        // 'reservation_guest_count': obj.guest_count,
        // 'reservation_timeslot': moment(obj.reservation_datetime).format('HH:mm'),
        'reservation_country': reservation.country_name,
        'language':defaultLang
        // 'reservation_outlet': venueDetails.outlet_name
      }
      if(queryParams.get('utm_source')){
        gtmEvent.utm_source = queryParams.get('utm_source');
      }
      if(queryParams.get('utm_medium')){
        gtmEvent.utm_medium = queryParams.get('utm_medium');
      }
      if(queryParams.get('utm_campaign')){
        gtmEvent.utm_campaign = queryParams.get('utm_campaign');
      }
      if(queryParams.get('utm_content')){
        gtmEvent.utm_content = queryParams.get('utm_content');
      }
      if(queryParams.get('Creative')){
        gtmEvent.Creative = queryParams.get('Creative');
      }
      if(queryParams.get('Adset')){
        gtmEvent.Adset = queryParams.get('Adset');
      }
      if(queryParams.get('_gl')){
        gtmEvent._gl = queryParams.get('_gl');
      }
      if (reservation.selectedCategories && reservation.selectedCategories.length > 0) {
        let reservation_category = '';
        let total = 0;
        if (reservation.selectedCategories[0].count) {
          reservation.selectedCategories.map(category => {
            total += parseInt(category.count) * parseInt(category.amount);
            reservation_category += category.count + ' ' + category.package_name + ', ';
          })
          gtmEvent.reservation_revenue = total;
        } else {
          reservation.selectedCategories.map(category => {
            reservation_category += category.package_name;
            total += parseInt(reservation.tableFor) * parseInt(category.amount)
          })
        }
        gtmEvent.reservation_packages = reservation_category;
        gtmEvent.reservation_revenue = total;
      }
    }

  

    obj = qs.stringify(obj);
    dispatch(updateProperty({ key: "reservation_type", value: type || 'normal' }));
    if (type === 'join_waitlist') {
      joinWaitlist(obj)
        .then((response) => {
          dispatch(decrementLoader());
          if (response.status === "success") {
            dispatch(updateProperty({ key: "referenceCode", value: response.operation_result.refrence_code }));
            
            const reservationInfo = {
              date: dateTime,
              time: reservation.time.time ? reservation.time.time : reservation.time.start_time,
              tableFor: reservation.tableFor,
              firstName: values.firstName,
              lastName: values.lastName,
              selectedCategories: reservation.selectedCategories,
              total: reservation.totalAmount,
              type: reservation.reservation_type,
              language: defaultLang,
              allergies: reservation.allergies,
              dietary: reservation.dietary,
              occasion: reservation.occasion,
              widget: reservation.widget,
              reservation_type: 'join_waitlist',
              referenceCode: '',
              payment_settings: reservation.section.payment_settings,
              country_name:reservation.country_name
            };
            localStorage.setItem('reservation_info', JSON.stringify(reservationInfo));

            if (parseInt(theme.allowCustomThankYouPage) && theme.customThankYouPageUrl) {
              window.location.replace(theme.customThankYouPageUrl)
            } else {
              history.push({
                pathname: '/success',
                search: window.location.search,
                state: { title: response.user_message ? response.user_message : t("your_reservation_was_successfully_sent_to_the_venue"), subtitle: 'test' },
                title: t('thank_you'),
                subtitle: response.user_message ? response.user_message : t('we_look_forward_to_seeing_you_again_soon'),
                description: response.user_message
              })
            }
          } else {
            history.push({
              pathname: '/failed',
              search: window.location.search,
              title: t("reservation_failed"),
              subtitle: response.error_description ? response.user_message.message ? response.user_message.message : response.user_message : response.user_message,
              description: ''
            })
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    } else {
      createReservation(obj)
        .then((response) => {
          dispatch(decrementLoader());
          if (response.status === "success") {
            dispatch(updateProperty({ key: "referenceCode", value: response.operation_result.refrence_code }));

            if (response.operation_result.payment_id) {
              const reservationInfo = {
                date: dateTime,
                time: reservation.time.time ? reservation.time.time : reservation.time.start_time,
                tableFor: reservation.tableFor,
                firstName: values.firstName,
                lastName: values.lastName,
                selectedCategories: reservation.selectedCategories,
                total: reservation.totalAmount,
                type: 'packages',
                language: defaultLang,
                allergies: reservation.allergies?reservation.allergies:'',
                dietary: reservation.dietary,
                occasion: reservation.occasion?reservation.occasion:'',
                widget: reservation.widget,
                reservation_type: 'normal',
                referenceCode: response.operation_result.refrence_code,
                paymentId: response.operation_result.payment_id,
                payment_settings: selectedPaymentGroup,
                country_name:reservation.country_name,
                country_isd:reservation.country_isd,
                email: values.emailAddress,
                phone_number: values.phoneNumber
              };
              if (queryParams.get('cid')) {
                reservationInfo.cid = queryParams.get('cid');
              }
              localStorage.setItem('reservation_info', JSON.stringify(reservationInfo));
              window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}payments/${(response.operation_result.payment_id)}/page`);
            } else {
              
            if (theme.googleTagsManager && theme.googleTagsManagerId) {
              gtmEvent.reservation_id = response.operation_result.id;
              gtmEvent.reservation_data += '(Reservation id: ' + response.operation_result.id + ')';
              TagManager.dataLayer({
                dataLayer: gtmEvent
              })
            }
              const reservationInfo = {
                date: storedDateTime,
                time: reservation.time.time ? reservation.time.time : reservation.time.start_time,
                tableFor: reservation.tableFor,
                firstName: values.firstName,
                lastName: values.lastName,
                selectedCategories: reservation.selectedCategories,
                total: reservation.totalAmount,
                type: reservation.reservation_type,
                language: defaultLang,
                allergies: reservation.allergies,
                dietary: reservation.dietary,
                occasion: reservation.occasion,
                widget: reservation.widget?reservation.widget:'',
                reservation_type: reservation.reservation_type === 'join_waitlist' ? t("waitlist_booking_details") : t("booking_details"),
                referenceCode: response.operation_result.refrence_code,
                payment_settings: selectedPaymentGroup,
                country_name:reservation.country_name,
                country_isd:reservation.country_isd,
                email: values.emailAddress,
                phone_number: values.phoneNumber
              };
              if (queryParams.get('cid')) {
                reservationInfo.cid = queryParams.get('cid');
                console.log(atob(reservationInfo.cid));
                const fbpixel_object = {
                    mealDate: reservationInfo.date, 
                    firstname: reservationInfo.firstName,
                    lastname: reservationInfo.lastName,
                    email: reservationInfo.email,
                    phoneNumber: '+' + reservationInfo.country_isd + ' '+reservationInfo.phone_number,
                    partySize: reservation.tableFor
                }
                console.log(fbpixel_object);
                ReactPixel.init(atob(reservationInfo.cid));
                ReactPixel.track("MZ_Booking_Confirmed",fbpixel_object)
              }
              localStorage.setItem('reservation_info', JSON.stringify(reservationInfo));
              history.push({
                pathname: '/success',
                search: window.location.search,
                title: response.user_message ? response.user_message : t("your_reservation_was_successfully_sent_to_the_venue"),
                subtitle: response.user_message ? '' : t('we_look_forward_to_seeing_you_again_soon'),
                description: response.user_message ? response.user_message : theme.thisDueTo
              });

              if (parseInt(theme.allowCustomThankYouPage) && theme.customThankYouPageUrl) {
                setTimeout(() => {
                  window.location.replace(theme.customThankYouPageUrl)
                }, 3000);
              }
            }

          } else {

            if (theme.googleTagsManager && theme.googleTagsManagerId) {
              gtmEvent.event = 'servme_reservation_failed';
              gtmEvent.reservation_data += '/Failure reason: ' + response.error_description + '.';
              TagManager.dataLayer({
                dataLayer: gtmEvent
              })
            }
            history.push({
              pathname: '/failed',
              search: window.location.search,
              state: { 
                title: t("reservation_failed"),
                subtitle:response.error_description ? response.user_message.message ? response.user_message.message : response.user_message : response.user_message,
                description: ''
              }
            })
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }else{
    setGuestCountError(true);
  }
  };

  function handleChange(event) {
    setForm({ ...form, [event.target.name]: event.target.value });
    dispatch(
      updateUserData({ key: event.target.name, value: event.target.value })
    );
  }

  const handlePhoneChnage = (value) => {
    let newForm = form;
    newForm.phoneNumber = value;
    setForm(newForm);
    setForm({ ...form, ["phoneNumber"]: value });
    dispatch(updateUserData({ key: "phoneNumber", value: value }));
  };

  const handleChecckboxChnage = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
    dispatch(
      updateUserData({ key: event.target.name, value: event.target.checked })
    );
  };

  const handleDaysRange = (selectedMonth) => {
    let selectedMonthDays = '';
    if (selectedMonth == 'September' || selectedMonth == 'April' || selectedMonth == 'June' || selectedMonth == 'November') {
      selectedMonthDays = 30;
    } else if (selectedMonth == 'February') {
      selectedMonthDays = 29;
    } else {
      selectedMonthDays = 31;
    }

    setEndOfMonth(selectedMonthDays);
    setDays(Array.from({ length: selectedMonthDays }, (v, k) => customPadStart(k + 1)));

    if (!Array.from({ length: selectedMonthDays }, (v, k) => customPadStart(k + 1)).includes(day)) {
      setDay('');
    }
  }

  function timeFormatter(time) {

    //let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
    let offset = moment.tz
    .zone(venueDetails.timezone)
    .utcOffset(moment(reservation.date + "T" + time));
    let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if (is_dst) {
    //   offset += 60;
    // }
    
    return moment(time, 'HH:mm').add(-offset, 'minutes').format('HH:mm')

  }

  function has_payment_activated() {
    return selectedPaymentGroup.is_active && selectedPaymentGroup.is_online_active;
    // return (reservation.section.intervals && reservation.section.intervals[0].payment_settings.is_active && reservation.section.intervals[0].payment_settings.is_online_active && ((reservation.section.intervals[0].payment_settings.packages_type == 'packages' && reservation.section.intervals[0].payment_type_packages.length > 0) || (reservation.section.intervals[0].payment_settings.packages_type == 'categories' && reservation.section.intervals[0].payment_type_categories.length > 0)))
  }

  function has_packages_activated() {

    return selectedPaymentGroup.hide_online_packages_categories==false;
    // return (type === 'normal' && reservation.section.intervals && reservation.section.shift_id && (reservation.section.intervals[0].payment_type_categories.length || reservation.section.intervals[0].payment_type_packages.length) && reservation.section.intervals[0].payment_settings.type === "direct" && reservation.section.intervals[0].payment_settings.is_active && (reservation.section.intervals[0].payment_settings.is_online_active || (!reservation.section.intervals[0].payment_settings.hide_online_packages_categories && !reservation.section.intervals[0].payment_settings.is_online_active)));
  }

  function dateOfBirth() {
    return (
      <>
        <LayoutItem md={6} sm={6} xs={6}>
          <FormControl variant="outlined" className={('chooseDateOfBirthSelect')}>
            <Controller
              name="birthDay"
              control={control}
              defaultValue={previewMode ? '01' : ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  fullWidth
                  displayEmpty
                  variant='outlined'
                  id={'birthDay'}
                  className={('chooseDateOfBirthSelect')}
                  name={'birthDay'}
                  value={previewMode ? '01' : day}
                  onChange={(e) => { onChange(e); setDay(e.target.value); }}
                  error={!!error}
                  startAdornment={
                    <InputAdornment position="start">
                      <Birthday size={18} />
                    </InputAdornment>
                  }
                  style={{ backgroundColor: 'white' }}
                  renderValue={(selected) => {
                    if (!selected || selected == '') { return <span variant='caption' style={{ fontFamily: colorDesign.fontFamily, opacity: 0.42, fontSize: '12px' }}>{t("birth_day") + '*'}</span> }
                    else {
                      return <span style={{ fontFamily: colorDesign.fontFamily, textTransform: 'capitalize', fontSize: '12px', textAlign: "left" }}>{selected}</span>
                    }
                  }}
                >
                  {days.map((day, key) => (
                    <MenuItem key={`day-${key}`} value={day}>{day}</MenuItem>
                  ))}
                </Select>
              )}
              rules={{ required: t("required") }}
            />
            {/* <span className="errorMessage">{errors.birthDay?.type === "required" && t("required")}</span>
            <span className="errorMessage">{month && day === "" && t("required")}</span> */}
          </FormControl>
        </LayoutItem>

        <LayoutItem md={6} sm={6} xs={6}>
          <FormControl variant="outlined" className={('chooseDateOfBirthSelect')}>
            <Controller
              name="birthMonth"
              control={control}
              defaultValue={previewMode ? 'January' : ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  fullWidth
                  displayEmpty
                  variant='outlined'
                  id={'birthMonth'}
                  className={('chooseDateOfBirthSelect')}
                  name={'birthMonth'}
                  value={value.value}
                  onChange={(e) => { onChange(e); handleDaysRange(e.target.value.name); setMonth(e.target.value.value); }}
                  error={!!error}
                  renderValue={(selected) => {
                    selected = previewMode ? { "name": "January", "value": "01" } : selected;
                    if (!selected || selected == '') { return <span variant='caption' style={{ fontFamily: colorDesign.fontFamily, opacity: 0.42, fontSize: '12px' }}>{t("birth_month") + '*'}</span> }
                    else {
                      return <span style={{ fontFamily: colorDesign.fontFamily, textTransform: 'capitalize', fontSize: '12px', textAlign: "left" }}>{selected.name}</span>
                    }
                  }}
                  style={{ backgroundColor: 'white' }}
                >
                  {months.map((month, key) => (
                    <MenuItem key={`month-${key}`} value={month}>{month.name}</MenuItem>
                  ))}
                </Select>
              )}
              rules={{ required: t("required") }}
            />
            {/* <span className="errorMessage">{errors.birthMonth?.type === "required" && t("required")}</span> */}
          </FormControl>
        </LayoutItem>
      </>
    );
  }

  function onChangeFeedbackValue(key, value) {
    dispatch(updateTagNote({ key: key, value: value }));
  }

  function onChangeFeedbackNote(key, value) {
    dispatch(updateNote({ key: key, value: value }));
  }

  function checkPhoneNumber(val) {
    let forms = JSON.parse(JSON.stringify(form));
    if (val[0].id == 118) {
      forms.newsletter = true;
    } else {
      forms.newsletter = false;
    };
    setForm(forms);
  }

  function onChangeNewsletter(value) {
    let forms = JSON.parse(JSON.stringify(form));
    forms.newsletter = value;
    setForm(forms);
  }

  function buttonTextToggle() {
    if (type === 'join_waitlist') {
      return t("join_the_waitlist");
    } else if (has_payment_activated()) {
      return t("proceed_to_payment");
    } else if (theme.confirmAndCompleteBooking && defaultLang==='en') {
      return theme.confirmAndCompleteBooking;
    } else {
      return t("complete_my_booking");
    }
  }

  const renderEmailField = () => {
    if (parseInt(theme.requestBirthdayInput)) {
      return (
        <LayoutItem xs={10}>
          <Controller
            name="emailAddress"
            control={control}
            defaultValue={previewMode ? 'john.wick@gmail.com' : ''}
            style={{ 'fontFamily': colorDesign.fontFamily }}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <TextField
                variant="outlined"
                placeholder={t("email_address") + "*"}
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                  style: { 'fontFamily': colorDesign.fontFamily, backgroundColor: 'white' }
                }}
              />
            )}
            rules={{
              required: t("required"),
            }}
          />
          {/* <span className="errorMessage">
            {errors.emailAddress?.type === "required" &&
              t("required")}
          </span> */}
        </LayoutItem>
      );
    } else {
      return (
        <LayoutItem md={5} sm={10} xs={10}>
          <Controller
            name="emailAddress"
            control={control}
            defaultValue={previewMode ? 'john.wick@gmail.com' : ''}
            style={{ 'fontFamily': colorDesign.fontFamily }}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <TextField
                variant="outlined"
                placeholder={t("email_address") + "*"}
                value={value}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                  style: { 'fontFamily': colorDesign.fontFamily, backgroundColor: 'white' }
                }}
              />
            )}
            rules={{
              required: t("required"),
            }}
          />
          {/* <span className="errorMessage">
            {errors.emailAddress?.type === "required" &&
              t("required")}
          </span> */}
        </LayoutItem>
      );
    }

  }

  const renderPhoneDateOfBirthField = () => {
    if (parseInt(theme.requestBirthdayInput)) {
      return (
        <LayoutItem xs={10} className={parseInt(theme.requestBirthdayInput) ? "date-of-birth-block" : 'mobileNumberr'}>
          <LayoutItem xs={parseInt(theme.requestBirthdayInput) ? 6 : 12}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={previewMode ? '+96171665669' : ''}
              style={{ fontFamily: colorDesign.fontFamily + '!important' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PhoneNumber
                  hideLabel="true"
                  value={value}
                  name="phoneNumber"
                  onMouseOut={checkPhoneNumber}
                  setCountryCode={true}
                  onChange={onChange}
                  style={{ 'fontFamily': colorDesign.fontFamily }}
                  placeholder={defaultLang === 'ar' ? "*" + t("mobile_number") : t("mobile_number") + "*"}
                  classes={errors.phoneNumber?.type === "required" ? 'phoneNumberError' : ''}
                ></PhoneNumber>
              )}
              rules={{
                required: t("required"),
              }}
            />
            {/* <span className="errorMessage">
              {errors.phoneNumber?.type === "required" &&
                t("required")}
            </span> */}
          </LayoutItem>
          {!!parseInt(theme.requestBirthdayInput) &&
            <LayoutItem xs={6} className="birthday-half-col">
              {dateOfBirth()}
            </LayoutItem>}
        </LayoutItem>
      );
    } else {
      return (
        <LayoutItem md={5} sm={10} xs={10} className={parseInt(theme.requestBirthdayInput) ? "date-of-birth-block" : 'mobileNumberr'}>
          <LayoutItem xs={parseInt(theme.requestBirthdayInput) ? 6 : 12}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={previewMode ? '+96171665669' : ''}
              style={{ fontFamily: colorDesign.fontFamily + '!important' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PhoneNumber
                  hideLabel="true"
                  value={value}
                  name="phoneNumber"
                  onMouseOut={checkPhoneNumber}
                  setCountryCode={true}
                  onChange={onChange}
                  style={{ 'fontFamily': colorDesign.fontFamily }}
                  placeholder={defaultLang === 'ar' ? "*" + t("mobile_number") : t("mobile_number") + "*"}
                  classes={errors.phoneNumber?.type === "required" ? 'phoneNumberError' : ''}
                ></PhoneNumber>
              )}
              rules={{
                required: t("required"),
              }}
            />
            {/* <span className="errorMessage">
              {errors.phoneNumber?.type === "required" &&
                t("required")}
            </span> */}
          </LayoutItem>
          {!!parseInt(theme.requestBirthdayInput) &&
            <LayoutItem xs={6} className="birthday-half-col">
              {dateOfBirth()}
            </LayoutItem>}
        </LayoutItem>
      );
    }
  }

 
  return (
    <div>
      <div className="loginContainer"
        style={widgetBackgroundColor}>
        <Header title={theme.headerTitle && theme.headerTitleValue && defaultLang==='en' ? theme.headerTitleValue : ''} />
        <div className="customFormPadding">
          <LayoutContainer
            alignItems="center"
            justifyContent="center"
            className="sectionTable"
          >
            <LayoutItem xs={10}>
              
              <ReservationInfo
                type={type}
                description={type === 'join_waitlist' ? t("you_are_joining_the_waitlist_for") : t("you_are_booking_table_for")}
                date={moment(reservation.date).format('ddd DD MMM YYYY')}
                time={waitlistTime === 'all' ? '' : timeFormatter(reservation.time.time)}
                separator={t('at')}
                waitlistTime={waitlistTime}
                persons={reservation.tableFor}
                link={{
                  text: has_packages_activated() ? t('modify_my_package') : t('modify_my_reservation'),
                  // link: previewMode ? '/' : selectedEvent.event_id ? (has_packages_activated() ? '/event/' + selectedEvent.event_id + '/payment-package/' : '/event/' + selectedEvent.event_id + '/') :selectedEvent.shift_id?'/': has_packages_activated() ? '/payment-package' : '/'
                }}
                package={reservation.selectedCategories && reservation.selectedCategories.length > 0 ? reservation.selectedCategories : []}
                total={reservation.totalAmount}
                guestCountError={!!guestCountError}
                control={control}
              />
              <Divider />
            </LayoutItem>
          </LayoutContainer>
        </div>
        <form className="customFormPadding loginFormContainer">
          <LayoutContainer
            spacing={2}
            alignItems="center"
            justifyContent="center"
            className="sectionTable"
          >
            <LayoutItem md={10} sm={10} xs={10}>
              <Typography
                className="note noteHeading"
                variant="caption"
                display="block"
                gutterBottom
                style={colorDesign}
              >
                {theme.pleaseConfirmYourBelowDetailsSoWeCanContactYouRegardingYourBooking && defaultLang==='en' ? theme.pleaseConfirmYourBelowDetailsSoWeCanContactYouRegardingYourBooking : t(
                  "please_confirm_your_below_details_so_we_can_contact_you_regarding_your_booking"
                )}
              </Typography>
            </LayoutItem>
            <LayoutItem md={5} sm={10} xs={10}>
              <Controller
                name="firstName"
                control={control}
                defaultValue={previewMode ? 'john' : ''}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    variant="outlined"
                    placeholder={t("first_name") + "*"}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    error={!!error}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person />
                        </InputAdornment>
                      ),
                      style: { 'fontFamily': colorDesign.fontFamily, backgroundColor: 'white' }
                    }}
                  />
                )}
                rules={{ required: t("required") }}
              />
              {/* <span className="errorMessage">
                {errors.firstName?.type === "required" &&
                  t("required")}
              </span> */}
            </LayoutItem>

            <LayoutItem md={5} sm={10} xs={10}>
              <Controller
                name="lastName"
                control={control}
                defaultValue={previewMode ? 'Wick' : ''}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    variant="outlined"
                    placeholder={t("last_name") + "*"}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    error={!!error}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person />
                        </InputAdornment>
                      ),
                      style: { 'fontFamily': colorDesign.fontFamily, backgroundColor: 'white' }
                    }}
                  />
                )}
                rules={{ required: t("required") }}
              />
              {/* <span className="errorMessage">
                {errors.lastName?.type === "required" &&
                  t("required")}
              </span> */}
            </LayoutItem>

            {renderEmailField()}

            {renderPhoneDateOfBirthField()}

            {/* <LayoutItem xs={10}>
              <FormControlLabel
                className="checkbox"
                style={colorDesign}
                control={
                  <Checkbox
                    checked={form.temporaryLocalNumber}
                    value={form.temporaryLocalNumber}
                    onChange={handleChecckboxChnage}
                    name="temporaryLocalNumber"
                    color="primary"
                  />
                }
                label={<div style={colorDesign}>{t("this_is_a_temporary_local_number_i_e_tourist_number")}</div>}
              />
            </LayoutItem> */}

            <LayoutItem xs={10} className="typographyNote noVerticalPadding">
              <Typography variant="caption" display="block" align="left"
                style={styleSmallText}>
                {t(
                  "your_mobile_is_required_to_confirm_the_reservation_we_will_not_spam_you_with_ads_or_promotions"
                )}

              </Typography>
              <Divider />
            </LayoutItem>

            <LayoutItem xs={10}>
              <FeedbackForm onChangeValue={onChangeFeedbackValue} onChangeNote={onChangeFeedbackNote} />
            </LayoutItem>

            <LayoutItem xs={10} className="notes-divider">
              <Divider />
            </LayoutItem>

            <LayoutItem xs={10} className="noVerticalPadding">
              <FormControlLabel
                className={errors.termsConditionAgree?.type === "required"?'checkbox privacyPolicyCheckbox Mui-error':'checkbox privacyPolicyCheckbox sasd'}
                style={colorDesign}
                control={
                  <Controller
                    name="termsConditionAgree"
                    control={control}
                    defaultValue={previewMode ? true : ''}
                    className={errors.termsConditionAgree?.type === "required"?'Mui-error':''}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        style={{ color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : '' }}
                        checked={!!value}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                    rules={{
                      required: t("required"),
                    }}
                  />
                }
                label={
                  <div>
                    <div style={colorDesign}>
                      {theme.ppPreferredName ? t("by_placing_your_reservation_you_understand_your_information_will_be_subject_to") + ' ' + theme.ppPreferredName + ' ' : t("by_placing_your_reservation_you_understand_your_information_will_be_subject_to_the_venues") + ' '}
                      {theme.ppTcLink ? <a target="_blank" href={theme.ppTcLink ? theme.ppTcLink : ''} style={{ color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : '' }}>{t("terms_and_conditions")}</a> : t("terms_and_conditions")}{" " + t('and') + ' '}
                      {theme.ppLink ? <a target="_blank" href={theme.ppLink ? theme.ppLink : ''} style={{ color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : '' }}>{t("privacy_policy")}</a> : t("privacy_policy")}
                      {/* {t("and_symbol")}{" "} */}
                      {/* <Link to="#">{t("gdpr_policy")}</Link> */}
                    </div>
                    <span className="errorMessage">
                      {errors.termsConditionAgree?.type === "required" &&
                        t("required")}
                    </span>
                  </div>
                }
              />
            </LayoutItem>

            {/* <LayoutItem xs={10} className="noVerticalPadding">
              <FormControlLabel
                className="checkbox"
                style={{fontFamily:colorDesign.fontFamily+'!important'}}
                control={
                  <Checkbox
                    value={form.newsletter}
                    onChange={handleChecckboxChnage}
                    name="newsletter"
                    color="primary"
                    style={colorDesign}
                  />
                }
                label={<div style={colorDesign}>{t(
                  "i_agree_to_receive_news_and_updates_about"
                )+' '+theme.ppPreferredName}</div>}
              />
            </LayoutItem> */}

            <LayoutItem xs={10} className="noVerticalPadding">
              <FormControlLabel
                className="checkbox privacyPolicyCheckbox noGap"
                style={{ fontFamily: colorDesign.fontFamily + '!important' }}
                value={!!form.newsletter}
                control={
                  <Checkbox
                    style={{ color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : '' }}
                    checked={!!form.newsletter}
                    onChange={() => onChangeNewsletter(!form.newsletter)}
                  />
                }
                label={<div style={colorDesign}>{t(
                  "i_agree_to_receive_news_and_marketing_material_for_promotional_purposes"
                )}</div>}
              />
            </LayoutItem>

            {/* <LayoutItem xs={10} className="noVerticalPadding">
              <FormControlLabel
                className="checkbox privacyPolicyCheckbox"
                style={colorDesign}
                control={
                  <Controller
                    name="termsConditionAgree"
                    control={control}
                    defaultValue={previewMode ? true : ''}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        style={{ color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : '' }}
                        checked={!!value}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                    rules={{
                      required: t("required"),
                    }}
                  />
                }
                label={
                  <div>
                    <div style={colorDesign}>
                      {t("by_placing_your_reservation_you_agree_to_the") + ' ' + venueDetails.outlet_name + t("s") + ' '}
                      {theme.ppTcLink ? <a target="_blank" href={theme.ppTcLink ? theme.ppTcLink : ''}>{t("payment_policy")}</a> : t("payment_policy")}
                    </div>
                    <span className="errorMessage">
                      {errors.termsConditionAgree?.type === "required" &&
                        t("required")}
                    </span>
                  </div>
                }
              />
            </LayoutItem> */}
{!! theme.captchaActive && 
    <LayoutItem xs={10} className={'captchaContainer'}>
        <Controller
                name="captcha"
                control={control}
                defaultValue={previewMode ? 'Wick' : ''}
                render={({
                  field: { onChange, captchaError },
                  fieldState: { error },
                }) => (
<ReCAPTCHA
            ref={recaptchaRef}
            size="normal"
            onChange={onChange}
            data-size="normal"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
                )}
                rules={{ required: t("required") }}
              />
              <span className="errorMessage">
                {errors.captcha?.type === "required" &&
                  t("this_is_required")}
              </span>
            </LayoutItem>
            }

            <LayoutItem xs={12} className="nextButtonContainer">
              <Button
                className="buttonFix"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                disabled={previewMode}
                style={buttonDesign}
                endIcon={defaultLang === 'ar'?<ArrowBackIosIcon style={{ color: buttonDesign.color }} />:<ArrowForwardIosIcon style={{ color: buttonDesign.color }} />}
              >
                {buttonTextToggle()}
              </Button>
            </LayoutItem>
            <LayoutItem xs={10} className="loginCopyright">
              <Copyright />
            </LayoutItem>

          </LayoutContainer>
        </form>
      </div>
    </div >
  );
}
