import * as React from 'react';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControl, InputAdornment, MenuItem, Select, Typography,TextField } from '@material-ui/core';
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';
import { ReactComponent as TimerIcon } from '../../assets/svg/timer.svg';
import { ReactComponent as PeopleIcon } from '../../assets/svg/persons.svg';
import { ReactComponent as PackageIcon } from '../../assets/svg/package.svg';
import { ReactComponent as PaymentIcon } from '../../assets/svg/payment.svg';
import Back from './Back';
import '../../assets/scss/ReservationInfo.scss';
import LayoutContainer from '../layout/LayoutContainer';
import LayoutItem from '../layout/LayoutItem';
import moment from "moment-timezone";
import { updateProperty } from "./../../features/store/storeSlice";
import 'moment-timezone';

export default function ReservationInfo(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { availableTimes, currency, reservation, theme, venueDetails,selectedPaymentGroup,allShiftsAvailableTimes,defaultLang } = useSelector((state) => state.store);
    const [time, setTime] = useState(reservation.time.time?reservation.time.time:allShiftsAvailableTimes[0]?.start_time);
    const [timeArray, setTimeArray] = useState(availableTimes[0]?.start_time);
    const style = { color: theme.calendarTextColor ? theme.calendarTextColor : "", fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "" };

    const colorDesign = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    };

    const styleSmallText = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
        opacity: 0.5,
        marginLeft: '10px',
        fontStyle: 'italic',
        fontSize: '12px'
    };

    useEffect(() => {
        if (props.waitlistTime && props.waitlistTime === 'all') {
            dispatch(updateProperty({ key: "time", value: { time: time } }));
        }
    }, [props.waitlistTime, time]);

    useEffect(() => {
        let current_time = moment().format('YYYY-MM-DD 6:00');
        let endTime=moment().add(1,'days').format('YYYY-MM-DD 6:00');
        current_time=moment.utc(moment(current_time));
        endTime=moment(endTime);
        let time_array=[];
        while (current_time < endTime) {
                time_array.push({
                    time: moment(current_time).format('HH:mm'),
                    display: timeFormatter(current_time),
                });
            current_time = moment(current_time).add(15, 'm');
        }
        setTimeArray(time_array);
    }, []);

    const totalDisplay = () => {
        if (history.location.pathname !== '/payment-success') {
            if (props.total && selectedPaymentGroup.is_active && selectedPaymentGroup.is_online_active) {
                return (
                    <Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><PaymentIcon /></i>{t("total_amount_to_pay")}:</span><strong style={colorDesign}>{`${currency} ${props.total}`}</strong></Typography>
                )
            }
            if (props.total && selectedPaymentGroup.is_active && !selectedPaymentGroup.is_online_active) {
                return (
                    <Typography className="reservation-item-header" variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><PaymentIcon /></i>{t("amount_to_pay")}:</span><strong style={colorDesign}>{`${currency} ${props.total}`}</strong><span className='reservation-item-subtitle' style={styleSmallText}>{t("payment_will_be_collected_at_the_venue")}</span></Typography>
                )
            }
        } else {
            return (
                <Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><PaymentIcon /></i>{t("total_amount_paid")}:</span><strong style={colorDesign}>{`${currency} ${props.total}`}</strong></Typography>
            )
        }
    }

    function joinWaitlistAt(e) {
        dispatch(updateProperty({ key: "time", value: { time: e.target.value } }));
    }

    function timeFormatter(time, format = 'HH:mm') {
        let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
        let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
        // if (is_dst) {
        //   offset += 60;
        // }
        return moment(time, format).add(-offset, 'minutes').format(format)
    }

    const displaySelectedPaymentType = () => {
        const packageType = t("package");
        let selectedPaymentTypes = '';
        // if (reservation.section.payment_settings.packages_type === "categories") {
            const categories = [];
            props.package.map(category => {
                categories.push(`${category.count} x ${category.package_name}`);
            });
            selectedPaymentTypes = categories.join(", ");
        // } else {
        //     selectedPaymentTypes = props.package[0].name;
        // }

        return (
            <Typography variant="subtitle1" gutterBottom style={style}><i className="icon"><PackageIcon /></i><span className="infoItem">{packageType}:</span> <strong style={colorDesign}>{selectedPaymentTypes}</strong></Typography>
        );
    }

    const onChangeGuestCount = (e) => {
        let regex = /^[1-9][0-9]*$/;
        let value = e.target.value;
        if (regex.test(value)) {
            dispatch(updateProperty({ key: "tableFor", value: e.target.value }));
        }
    }

    return (
        <div className='infoContainer'>
            <div className="title">{props.link ? <Back link={props.link} /> : props.name}</div>
            {props.description && <Typography variant="subtitle1" gutterBottom style={colorDesign}>{props.description}</Typography>}

            <Typography variant="subtitle1" gutterBottom style={style}lassName="icon-list-block">
                <span className='reservation-item'><i className="icon"><Calendar /></i>{t("date_&_time")}:</span> <div className='date-time-container'><strong style={colorDesign}>{moment(props.date).locale(defaultLang).format('ddd DD MMM YYYY')} {!props.type || props.type !== 'join_waitlist'?props.time:'' }</strong></div>
            </Typography>

            {props.type && props.type === 'join_waitlist' && <Typography variant="subtitle1" gutterBottom style={style} className="icon-list-block chooseTimeSelectContainer">
                <span className='reservation-item'><i className='icon'> <TimerIcon /></i>{t('time')}: </span>
                <div>
                    <FormControl variant="outlined" className='chooseTimeSelect'>
                        <Select
                            onChange={(e) => { joinWaitlistAt(e); setTime(e.target.value); }}
                            // startAdornment={
                            //     <InputAdornment position="start">
                            //         <TimerIcon />
                            //     </InputAdornment>
                            // }
                            value={time}
                        >
                            {props.waitlistTime && props.waitlistTime === 'all' && timeArray && timeArray.length ? timeArray.map((value, key) => (
                                <MenuItem key={`wait-list-${key}`} value={value.time}>{value.display}</MenuItem>
                            )):allShiftsAvailableTimes.filter(item=> {return item.allow_waitlist===true}).map((value, key) => (
                                <MenuItem key={`wait-list-${key}`} value={value.start_time}>{timeFormatter(value.start_time, 'HH:mm')}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </Typography>}

            <Typography variant="subtitle1" gutterBottom style={style} className="icon-list-block icon-list-block-guest">
                <span className='reservation-item'><i className="icon"><PeopleIcon /></i>{t("number_of_guests")}:</span>
                {props.type && props.type === 'join_waitlist' ?
                <div>
                    <FormControl variant="outlined" className='chooseTimeSelect'>
                        <TextField
                            variant="outlined"
                            value={reservation.tableFor}
                            onChange={onChangeGuestCount}
                            className={props.guestCountError?'error':''}
                            type='number'
                            inputProps={{min:1}}
                        />
                    </FormControl></div>:<strong style={colorDesign}>{defaultLang==='ar'?props.persons.toLocaleString('ar-u-nu-arab'):props.persons}</strong>}
                
            </Typography>
            {props.package && props.package.length > 0 && displaySelectedPaymentType()}
            {props.type && props.type === 'join_waitlist' ? '' : totalDisplay()}
        </div >
    );
}