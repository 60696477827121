import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import "moment-timezone";
import { Button, Divider, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Header from "./Header";
import ReservationInfo from "./ReservationInfo";
import Packages from "../event/Packages";
import Copyright from './Copyright';
import './../../assets/scss/PaymentPackage.scss';
import { updateProperty } from "./../../features/store/storeSlice";
import { ReactComponent as TicketIcon } from '../../assets/svg/ticket-icon.svg';
import { ReactComponent as PersonIcon } from '../../assets/svg/person.svg';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export default function PaymentPackage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { currency, previewMode, reservation, theme, venueDetails, selectedPaymentGroup, selectedEvent, defaultLang } = useSelector((state) => state.store);
  const [capacityError, setCapacityError] = useState("");
  const [countError, setCountError] = useState("");
  const [total, setTotal] = useState(0);
  const [packagesSeats, setPackagesSeats] = useState(0);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [guestCountError, setGuestCountError] = useState(false);

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
    margin: '15px 0px'
  };


  useEffect(() => {
    // if (reservation.section.category === "regular") {
    calculateTotal(reservation.selectedCategories, reservation.tableFor);
    // }
    // else {
    //   calculateEventTotal(reservation.selectedCategories, reservation.tableFor)
    // }
  }, []);

  function timeFormatter(time) {
    let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
    return moment(time, 'HH:mm').add(-offset, 'minutes').format('HH:mm')
  }

  function packages() {
    if (
      (reservation.section.shift_id || selectedEvent.event_id) && (selectedPaymentGroup.hide_online_packages_categories == false || selectedPaymentGroup.is_online_active == true)
    ) {

      return (
        <LayoutItem xs={12}>
          <LayoutContainer>
            <LayoutItem xs={12}>
              <Divider />
            </LayoutItem>
            <LayoutItem xs={12}>
              <Typography
                variant="h5"
                display="block"
                align="center"
                className="sectionTitle SelectedMonth"
              >
                <div style={colorDesign}>
                  {t("please_select_a_payment_category")}
                  {!has_payment_activated() && <span className="paymentLab">{t("payment_will_be_collected_at_the_venue")}</span>}
                </div>
              </Typography>
             
                          </LayoutItem>
                          
            <Packages
              list={(reservation.duration || reservation.duration === 0) ? selectedPaymentGroup.packages.filter((item) => item.duration === reservation.duration) : selectedPaymentGroup.packages.filter((item) => !item.parent_id)}
              vatValue={venueDetails.vat_percentage}
              type={'categories'}
              onChoosePackage={onChoosePackage}
              onChooseNumber={onChoosePackageNumber}
            />
            <span className="errorMessage">{countError}</span>
            <span className="errorMessage">
              {capacityError
                ? t("max_allowed_party_is") +
                " " +
                reservation.section.max_guest_count
                : ""}
            </span>
            {!reservation.selectedCategories.length && nextButtonClicked &&
              <LayoutItem xs={12}>
                <span className="errorMessage">
                  {!reservation.selectedCategories.length && nextButtonClicked
                    ? t("required")
                    : ""}
                </span>
              </LayoutItem>
            }
            {reservation.section.shift_id &&
              <LayoutItem xs={12}>
                <span className="errorMessage">
                  {reservation.section.shift_id ? ((selectedPaymentGroup.is_active && !selectedPaymentGroup.is_online_active) || !selectedPaymentGroup.is_active)
                    ? t('payment_is_not_required_for_this_booking')
                    : "" : ""}
                </span>
              </LayoutItem>
            }
            {guestCountError &&
              <LayoutItem xs={12}>
                <span className="errorMessage">
                  {t('the_number_of_guests_under_categories_must_equal_the_number_of_guests_in_the_reservation')}
                </span>
              </LayoutItem>
            }
          </LayoutContainer >
        </LayoutItem>
      );
    }
  }

  function onChoosePackage(value, index) {
    // if (reservation.section.intervals[0].payment_settings.packages_type === "categories") {
    let selectedCategory = JSON.parse(
      JSON.stringify(reservation.selectedCategories)
    );
    let exist = selectedCategory.filter((item) => item.package_id === value.package_id);
    if (exist.length) {
      selectedCategory = selectedCategory.filter(
        (item) => item.package_id !== value.package_id
      );
    } else {
      let vals = JSON.parse(JSON.stringify(value))
      vals.count = 1;
      selectedCategory.push(vals);
    }
    dispatch(
      updateProperty({ key: "selectedCategories", value: selectedCategory })
    );


    // if (reservation.section.category === "regular") {
    calculateTotal(selectedCategory, reservation.tableFor);
    // }
    // else {
    //   calculateEventTotal(selectedCategory, reservation.tableFor)
    // }
    // } else {
    //   dispatch(updateProperty({ key: "selectedCategories", value: [value] }));
    //   if (reservation.section.category === "regular") {
    //     calculateTotal([value], reservation.tableFor);
    //   }
    //   else {
    //     calculateEventTotal([value], reservation.tableFor)
    //   }
    // }
  }

  function onChoosePackageNumber(value, index, selected) {
    let selectedCategories = JSON.parse(
      JSON.stringify(reservation.selectedCategories)
    );
    let sel = selectedCategories.filter((item) => {
      return item.package_id === selected.package_id;
    })
    let indexOfSelected = selectedCategories.indexOf(sel[0]);
    selectedCategories[indexOfSelected].count = value;
    dispatch(
      updateProperty({ key: "selectedCategories", value: selectedCategories })
    );

    // if (reservation.section.category === "regular") {
    calculateTotal(selectedCategories, reservation.tableFor);
    // }
    // else {
    //   calculateEventTotal(selectedCategories, reservation.tableFor)
    // }
  }

  function has_payment_activated() {
    return selectedPaymentGroup.is_active && selectedPaymentGroup.is_online_active;
  }
  function number_format(number, decimals = 0, decPoint = '.', thousandsSep = '') {
    const multiplier = Math.pow(10, decimals);
    const roundedNumber = Math.round(number * multiplier) / multiplier;
    const parts = roundedNumber.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
    return parts.join(decPoint);
  }




  function calculateTotal(list, tableFor) {
    let total = 0;
    let countOfSeats = 0;
    let isVatIncluded = reservation.section.payment_group ? reservation.section.payment_group.is_vat_included : false;
    if (selectedEvent.event_id) {
      isVatIncluded = selectedEvent.payment_group ? selectedEvent.payment_group.is_vat_included : false;
    }
    // let isVatIncluded = reservation.section.payment_group && reservation.section.payment_group.is_vat_included ? reservation.section.payment_group.is_vat_included : false;
    if (
      list && list.length
    ) {
      list.forEach((item) => {
        let val = parseInt(item.count);
        let numberOfSeats = parseInt(item.count);
        if (item.count === "") val = 0;
        if (item.booking_per === "reservation") {
          numberOfSeats = parseInt(item.count) * parseInt(item.max_guest_count);
        }
        countOfSeats = countOfSeats + numberOfSeats;
        var tempAmount = number_format(item.package_amount, 2);
        total = total + (tempAmount * val);
      });
      let vat_percentage = venueDetails.vat_percentage;
      if (!isVatIncluded && vat_percentage && (vat_percentage !== '' || vat_percentage !== 0)) {
        total = total * (1 + parseInt(vat_percentage) / 100);

      }
      // if (reservation.selectedEvent.intervals[0].payment_settings.packages_type === 'categories') {
      //   dispatch(updateProperty({ key: "tableFor", value: countOfSeats }));
      // }
    }
    //  else {
    //   if (list[0]) {
    //     total = list[0].amount && tableFor ? list[0].type_of_package === 'person' ? total + parseInt(list[0].amount) * tableFor : total + parseInt(list[0].amount) : total;
    //   }
    // }
    total = number_format(total, 2);
    setTotal(total);
    setPackagesSeats(countOfSeats);
    dispatch(
      updateProperty({ key: "totalAmount", value: total })
    );
  }

  function calculateEventTotal(list, tableFor = reservation.tableFor) {
    let total = 0;
    let countOfSeats = 0;
    if (
      reservation.selectedEvent.shift_id && reservation.selectedEvent.intervals[0].payment_settings.packages_type === 'categories'
    ) {
      list.forEach((item) => {
        let val = parseInt(item.count);
        if (item.count === "") val = 0;
        countOfSeats = countOfSeats + val;
        total = total + parseInt(item.amount) * val;
      });
      if (countOfSeats !== reservation.tableFor) {
        setGuestCountError(true);
      } else {
        setGuestCountError(false);
      }
      // if (reservation.selectedEvent.intervals[0].payment_settings.packages_type === 'categories') {
      //   dispatch(updateProperty({ key: "tableFor", value: countOfSeats }));
      // }
    } else {
      if (list[0]) {
        total = list[0].amount && tableFor ? list[0].type_of_package === 'person' ? total + parseInt(list[0].amount) * tableFor : total + parseInt(list[0].amount) : total;
        countOfSeats = tableFor;
        dispatch(updateProperty({ key: "tableFor", value: countOfSeats }));
      }
    }

    setTotal(total);
    dispatch(updateProperty({ key: "totalAmount", value: total }));
  }

  function validatePackages() {
    // if (reservation.selectedEvent.intervals && reservation.selectedEvent.intervals[0].payment_settings.packages_type === 'categories') {
    //   return reservation.selectedCategories.length && !guestCountError ? true : false;
    // }
    return reservation.selectedCategories.length && reservation.tableFor <= packagesSeats ? true : false;
  }

  function next() {
    setNextButtonClicked(true);
    if (validatePackages()) {
      history.push({
        pathname: "/login",
        search: window.location.search
      });
    } else {
      window.scrollTo(0, 0);
    }
  }

  return (
    <LayoutContainer
      alignItems="center"
      justifyContent="center"
      className="paymentPackagePageContainer"
      style={widgetBackgroundColor}
    >
      <Header title={theme.headerTitle && theme.headerTitleValue && defaultLang === 'en' ? theme.headerTitleValue : ''} />
      <LayoutItem xs={10}>
        <ReservationInfo
          description={t("you_are_booking_table_for")}
          date={moment(reservation.date).format('ddd DD MMM YYYY')}
          time={timeFormatter(reservation.time.time)}
          separator={t('at')}
          persons={reservation.tableFor}
          link={{ text: t('modify_my_reservation') }}
        />
      </LayoutItem>

      <LayoutItem xs={10} align="center" className={!has_payment_activated() ? 'packagesWithoutPayment' : ''}>
        {packages()}
      </LayoutItem>
      {/* {has_payment_activated() ? */}
      <>
        <LayoutItem xs={10}><Divider /></LayoutItem>
        <LayoutItem
          md={10}
          className={"package payment-package-total padding-lr-0"}
        >
          <div className="packageItem">
            <LayoutItem xs={8} md={8} className="packageDescription">
              <div className="packageName">{t("total_amount_to_be_paid")}</div>
            </LayoutItem>
            <LayoutItem xs={4} md={4} className="packagePrice">{currency + " " + total}</LayoutItem>
          </div>
          <div className="packageItem">
            <LayoutItem xs={5} md={5} className="packageDescription">
              <div className="packageName"><PersonIcon /> <span className={packagesSeats < reservation.tableFor && "ticketsNumber"}>{packagesSeats}/{reservation.tableFor}</span> {t('guests')}</div>
            </LayoutItem>
          </div>
        </LayoutItem>
        <LayoutItem xs={10}><Divider /></LayoutItem>
      </>
      {/* :''} */}

      <LayoutItem xs={12}>
        <Button
          variant="contained"
          className="buttonFix"
          onClick={next}
          style={buttonDesign}
          disabled={previewMode}
          endIcon={defaultLang === 'ar' ? <ArrowBackIosIcon style={{ color: buttonDesign.color }} /> : <ArrowForwardIosIcon style={{ color: buttonDesign.color }} />}
        >
          {t("next")}
        </Button>
      </LayoutItem>

      <LayoutItem xs={10}>
        <Copyright />
      </LayoutItem>

    </LayoutContainer>
  );
}