import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import 'moment-timezone';
import { useTranslation } from "react-i18next";
import { Button, Divider, Typography } from "@material-ui/core";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import { updateProperty } from "./../../features/store/storeSlice";
import "./../../assets/scss/AvailableTime.scss";
import Carousel from "nuka-carousel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import StarIcon from "@material-ui/icons/Star";

export default function AvailableTime({ sections, selectSection, props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { availableTimes, theme, venueDetails, allShiftsAvailableTimes, selectedEvent,defaultLang } = useSelector((state) => state.store);
  const reservation = useSelector((state) => state.store.reservation);
  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState([0]);
  const [timeChanged, setTimeChanged] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [showWaitlistError, setShowWaitlistError] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;

  const outlineButtonDesign = {
    backgroundColor: "transparent",
    color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    borderColor: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const defaultBoxColor = {
    backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : '',
    opacity: theme.defaultBoxOpacity ? theme.defaultBoxOpacity : '',
    color: theme.calendarLinkColor ? theme.calendarLinkColor : '',
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : '',
  };

  const selectedStyle = {
    color: theme.calendarSelectedColor ? theme.calendarSelectedColor : '',
    backgroundColor: theme.calendarSelectedAreaColor ? theme.calendarSelectedAreaColor : '',
    opacity: theme.selectedColorOpacity ? theme.selectedColorOpacity : '',
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ''
  };

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const fontDesign = {
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const style = { ...defaultBoxColor, ...selectedStyle };

  const dispatch = useDispatch();
  const [state, setState] = useState({
    allergies: "",
    preferences: "",
    occasion: "",
    requests: "",
  });

  useEffect(() => {
    if(reservation.time.time && !timeChanged){
      let intervals=allShiftsAvailableTimes;
      let res=intervals.find(item=>{
        return reservation.time.time===item.start_time;
      })
      let index=intervals.indexOf(res);
            if(index>=5)
        setSelectedCarouselIndex(index);
    }
  }, [reservation.time])


  useEffect(() => {
    if(allShiftsAvailableTimes.length<6 || reservation.time.time==''){
      setSelectedCarouselIndex(0);
      return false;
    }
    if(reservation.time.time){
      let intervals=allShiftsAvailableTimes;
      let res=intervals.find(item=>{
        return reservation.time.time===item.start_time;
      })
      let index=intervals.indexOf(res);
      if(index>=5)
        setSelectedCarouselIndex(intervals[index+2]?index-2:index);
      else      
        setSelectedCarouselIndex(intervals[index-2]?2:0);
    }
  }, [allShiftsAvailableTimes])

  function chooseTime(shift_id,event_id, start_time, capacity) {
    setTimeChanged(true);
    setShowWaitlistError(false);
    let value={};
    if(event_id){
      value=JSON.parse(JSON.stringify(selectedEvent));
      value.shift_id=shift_id;
    }
    else
      value=sections.find(shi=>{
        return shi.shift_id===shift_id
      })
    selectSection(value, start_time, capacity)
  }

  function joinWaitListToggle(toggle, time) {
    if (toggle) {
      return (
        // <a className="join-wailist-btn" onClick={(e) => {
        //   e.preventDefault(); // to prevent anchor tag from reloading the page
        //   joinWaitListClicked(time);
        // }}
        <a className="join-wailist-btn" onClick={()=>joinWaitListClicked(time)}
          style={{ pointerEvents: "auto" }} // to make the anchor tag clickable
        >{t("join_waitlist_question_symbol")}</a>);
    } else {
      return "";
    }
  }
  
  function joinWaitListClicked(time) {
    if(!time){
      setShowWaitlistError(true)
    }
    if (time)
   
      dispatch(updateProperty({ key: "time", value: { time: time } }));
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    if (reservation.tableFor==0) {
      dispatch(updateProperty({ key: "tableFor", value: 1 }));
    }
      history.push(
        {
          pathname: '/login',
          search: '?oid=' + oid,
          type: 'join_waitlist',
          waitlistTime: time ? time : 'all'
        });
  }

  function permission(type) {
    return theme[type] ? true : false
  }

  function timeFormatter(time, format = 'HH:mm') {
    
    let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date+'T'+time));
    let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if (is_dst) {
    //   offset += 60;
    // }moment(date).locale(defaultLang).format(format)
    return moment(time, format).locale(defaultLang).add(-offset, 'minutes').format(format)
  }

  const checkCapacityExists = (value) => {
    if (reservation && reservation.section_id && (value.capacity === 0 || value.section_capacity === 0)) {
      return true;
    } else if (reservation && reservation.section_id === 0 && (value.capacity === 0 || value.outlet_capacity === 0)) {
      return true;
    } else {
      return false;
    }
  }

  function checkIfEvent(value) {
    if (value.category === "special") {
      return <StarIcon className="starIcon" />;
    }
    return "";
  }

  useEffect(() => {
    if (width < 600 && width > 500) {
      setSlidesToShow(4);
    } else if (width <= 500) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(5);
    }
  }, [width]);

  return (
    <div id="avaialableTime" className="dateSlider">
      <LayoutContainer
        spacing={1}
        alignItems="center"
        justifyContent="center"
        className="sectionTable"
      >
        <LayoutItem xs={12}>
          <Carousel
            slideIndex={selectedCarouselIndex}
            slidesToScroll={2}
            withoutControls={false}
            slidesToShow={slidesToShow}
            renderBottomCenterControls={() => {
              return false;
            }}
            cellSpacing={0}
            renderCenterLeftControls={({ previousSlide }) => (
              <ChevronLeftIcon
              // className={(allShiftsAvailableTimes.length > slidesToShow) ? "carouselRenderButtons" : "carouselRenderButtons hideButton"}
              className={'carouselRenderButtons'}
                onClick={previousSlide}
              />
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ChevronRightIcon
              // className={(allShiftsAvailableTimes.length > slidesToShow) ? "carouselRenderButtons" : "carouselRenderButtons hideButton"}
              className={'carouselRenderButtons'}
                onClick={nextSlide}
              />
            )}
          >
            {
            // props.mode !== 'event' ? (
            //   sections.map((value, index) =>
            //     value.available_time.map(({ start_time, capacity }, index) => {
                  // if (reservation.tableFor <= capacity && reservation.tableFor <= value.max_guest_count) {
                  //   return (
                  //     <div key={index} className="available-time">
                  //       <Button
                  //         variant="contained"
                  //         disableElevation
                  //         onClick={(e) => chooseTime(value, start_time, capacity)}
                  //         fullWidth
                  //         disabled={value.disabled}
                  //         test={reservation.time}
                  //         style={{ ...(reservation.time.time === start_time) ? style : defaultBoxColor }}
                  //         className={
                  //           reservation.time.start_time === start_time
                  //             ? "selected iWannaHaveButton widgetDefaultButton"
                  //             : " iWannaHaveButton widgetDefaultButton"
                  //         }
                  //       >
                  //         {timeFormatter(start_time, 'HH:mm')}
                  //         {/* {checkIfEvent(value)} */}
                  //       </Button>
                  //     </div>
                  //   );
                  // } else if (!permission('showHideTimeslots')) {
                  //   return (
                  //     <div xs={6} md={3} sm={3} className={`available-time joinWaitListButton ${reservation.section.allow_waitlist ? "joinWaitListButtonLabel" : ''}`} key={`available-time-${index}`}>
                  //       <Button
                  //         variant="contained"
                  //         disabled={true}
                  //         style={fontDesign}
                  //         fullWidth
                  //         // style={fontDesign}
                  //         className="widgetDefaultButton"
                  //       >
                  //         <span style={{ display: 'flex', flexDirection: 'column' }}>
                  //           {timeFormatter(start_time, 'HH:mm')}
                  //           {joinWaitListToggle(reservation, start_time)}
                  //         </ span>
                  //       </Button>
                  //     </div>
                  //   );
                  // }
            //     }))
            // ) : (
              allShiftsAvailableTimes.map(({ start_time, capacity,shift_id,event_id,allow_waitlist,shift_max_cover }, index) => {
                if (reservation.tableFor <= capacity && reservation.tableFor <= shift_max_cover && capacity!==0) {
                  return (
                    <div key={index} className="available-time">
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={(e) => chooseTime(shift_id,event_id, start_time, capacity)}
                        fullWidth
                        disabled={selectedEvent.disabled}
                        test={reservation.time}
                        style={{ ...(reservation.time.time === start_time) ? style : defaultBoxColor }}
                        className={
                          reservation.time.start_time === start_time
                            ? "selected iWannaHaveButton widgetDefaultButton"
                            : " iWannaHaveButton widgetDefaultButton"
                        }
                      >
                        {timeFormatter(start_time, 'HH:mm')}
                      </Button>
                    </div>
                  );
                } else if (!permission('showHideTimeslots')) {
                  return (
                    <div xs={6} md={3} sm={3} className={`available-time joinWaitListButton ${allow_waitlist ? "joinWaitListButtonLabel" : ''}`} key={`available-time-${index}`}>
                    {joinWaitListToggle(allow_waitlist, start_time)}
                      <Button
                        variant="contained"
                        disabled={true}
                        style={fontDesign}
                        fullWidth
                        className="widgetDefaultButton"
                      >
                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                          {timeFormatter(start_time, 'HH:mm')}
                        </span>
                      </Button>
                    </div>
                  );
                }
              })
            // )
            }
          </Carousel>
        </LayoutItem>
       
        {(showWaitlistError && reservation.time.time == '' ) &&
        <LayoutItem xs={12}>
        <span className="errorMessage timeErrorWaitlist">
          { t('required')}
        </span>
      </LayoutItem>
        }

        {!reservation.time &&  props.nextButtonClick &&
          <LayoutItem xs={12}>
            <span className="errorMessage ">
              {!reservation.time && props.nextButtonClick ? t('required') : ''}
            </span>
          </LayoutItem>}

        {props?.timeErrorMessage &&
          <LayoutItem xs={12}>
            <span className="errorMessage">
              {props?.timeErrorMessage
                ? t("we_have_reached_maximum_capacity_at_this_time_please_select_a_different_time_or_date")
                : ""}
            </span>
          </LayoutItem>}

        {!!theme.allowGuestToJoinWaitlistAnyTime &&
          <>
            <LayoutItem xs={12} md={8} className="joinListTitle">
              <Typography
                variant="caption"
                display="block"
                align="left"
                className="sectionTitle SelectedMonth"
              >
                <div
                  style={colorDesign}>
                  {t("cannot_find_the_time_you_are_looking_for?")}
                </div>
              </Typography>
            </LayoutItem>
            <LayoutItem xs={11} md={4} className="joinAllTimeWaitlistContainer joinBtn">
              <Button
                variant="outlined"
                onClick={(e) => joinWaitListClicked()}
                fullWidth
                style={outlineButtonDesign}
              >{theme.joinTheWaitlist || t('join_the_waitlist?')}
              </Button>
            </LayoutItem>
          </>
        }
      </LayoutContainer>
    </div>

    // <LayoutContainer
    //   spacing={1}
    //   className="availableTimeSection"
    //   justifyContent="flex-start"
    // >
    //   <LayoutItem xs={12}>
    //     <Divider />
    //   </LayoutItem>

    //   <LayoutItem xs={12} className="block-heading">
    //     <Typography
    //       variant="h5"
    //       display="block"
    //       align="center"
    //       className="sectionTitle SelectedMonth"
    //     >
    //       <div
    //         style={colorDesign}>
    //         {t("available_time")}
    //       </div>
    //     </Typography>
    //   </LayoutItem>

    //   {availableTimes.map((value, index) => {
    //     console.log(value)
    //     if (reservation.tableFor <= value.capacity && reservation.tableFor <= reservation.section.max_guest_count) {
    //       return (
    //         <LayoutItem xs={6} md={3} sm={3} className="available-time" key={`available-time-${index}`}>
    //           <Button
    //             disabled={checkCapacityExists(value)}
    //             variant="contained"
    //             onClick={(e) => chooseTime(value)}
    //             className={(reservation.time.start_time === value.start_time) ? "selected" : " "}
    //             style={(reservation.time.start_time === value.start_time) ? style : defaultBoxColor}
    //             fullWidth
    //           >
    //             {timeFormatter(value.start_time, 'HH:mm')}
    //           </Button>
    //         </LayoutItem>
    //       );
    //     }
    //     else if (!permission('showHideTimeslots')) {
    //       return (
    //         <LayoutItem xs={6} md={3} sm={3} className={`available-time joinWaitListButton ${reservation.section.allow_waitlist ? "joinWaitListButtonLabel" : ''}`} key={`available-time-${index}`}>
    //           <Button
    //             variant="contained"
    //             disabled={true}
    //             fullWidth
    //             style={fontDesign}
    //           >{timeFormatter(value.start_time, 'HH:mm')}
    //           </Button>
    //           {joinWaitListToggle(reservation.section.allow_waitlist, value)}
    //         </LayoutItem>
    //       );
    //     }
    //   })}


    //   {!reservation.time.start_time && props.nextButtonClick &&
    //     <LayoutItem xs={12}>
    //       <span className="errorMessage">
    //         {!reservation.time.start_time && props.nextButtonClick ? t('required') : ''}
    //       </span>
    //     </LayoutItem>}

    //   {props.timeErrorMessage &&
    //     <LayoutItem xs={12}>
    //       <span className="errorMessage">
    //         {props.timeErrorMessage
    //           ? t("we_have_reached_maximum_capacity_at_this_time_please_select_a_different_time_or_date")
    //           : ""}
    //       </span>
    //     </LayoutItem>}

    //   {!!reservation.section.allow_guest_to_join_waitlist_any_time &&
    //     <>
    //       <LayoutItem xs={12} className="pdTop10">
    //         <Typography
    //           variant="caption"
    //           display="block"
    //           align="left"
    //           className="sectionTitle SelectedMonth"
    //         >
    //           <div
    //             style={colorDesign}>
    //             {t("cannot_find_the_time_you_are_looking_for?")}
    //           </div>
    //         </Typography>
    //       </LayoutItem>
    //       <LayoutItem xs={12} className="joinAllTimeWaitlistContainer">
    //         <Button
    //           variant="outlined"
    //           onClick={() => joinWaitListClicked()}
    //           fullWidth
    //           style={outlineButtonDesign}
    //         >{theme.joinTheWaitlist || t('join_the_waitlist?')}
    //         </Button>
    //       </LayoutItem>
    //     </>
    //   }

    // </LayoutContainer>
  );
}
