

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip, Typography } from '@material-ui/core';
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';
import { ReactComponent as PeopleIcon } from '../../assets/svg/persons.svg';
import { ReactComponent as PaymentIcon } from '../../assets/svg/payment.svg';
import { ReactComponent as TagIcon } from '../../assets/svg/tag.svg';
import { ReactComponent as NotesIcon } from '../../assets/svg/notes.svg';
import { ReactComponent as ReferenceIcon } from '../../assets/svg/reference_nb.svg';
import { ReactComponent as CalendarIcon } from '../../assets/svg/add-to-calendar.svg';
import { ReactComponent as PdfIcon } from "../../assets/svg/pdf-icon.svg";
import '../../assets/scss/ReservationDetail.scss';
import LayoutItem from '../layout/LayoutItem';
import moment from "moment-timezone";
import 'moment/locale/ar';
import 'moment/locale/ru';
import 'moment/locale/de';

export default function ReservationDetail(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { allergiesTags, currency, dietaryTags, occasionsTags, previewMode, theme, widgetTags,defaultLang } = useSelector((state) => state.store);
    const style = { color: theme.calendarTextColor ? theme.calendarTextColor : "", fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "" };

    const styleSmallText = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
        opacity: 0.5,
        marginLeft: '10px',
        fontStyle: 'italic',
        fontSize: '12px'
    };

    const tags = {
        widget: widgetTags ? widgetTags : [],
        allergies: allergiesTags ? allergiesTags : [],
        occasion: occasionsTags ? occasionsTags : [],
        dietary: dietaryTags ? dietaryTags : [],
    };

    const totalDisplay = () => {
        if (history.location.pathname !== '/payment-success' && previewMode === false) {
            if (props.total && props.paymentSettings && props.paymentSettings.is_active && props.paymentSettings.is_online_active) {
                return (
                    <Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><PaymentIcon /></i>{t("total_amount_to_pay")}:</span><strong style={style}>{`${currency} ${props.total}`}</strong></Typography>
                )
            }
            if (props.total && props.paymentSettings && props.paymentSettings.is_active && !props.paymentSettings.is_online_active) {
                return (
                    <Typography className="reservation-item-header" variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><PaymentIcon /></i>{t("amount_to_pay")}:</span><strong style={style}>{`${currency} ${props.total}`}</strong><span className='reservation-item-subtitle' style={styleSmallText}>{t("payment_will_be_collected_at_the_venue")}</span></Typography>
                )
            }
        } else {
            return (
                <Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><PaymentIcon /></i>{t("total_amount_paid")}:</span><strong style={style}>{`${currency} ${props.total}`}</strong></Typography>
            )
        }
    }

    const displayTags = () => {
        let tags = [];
        if (props.allergies) {
            const allergies = props.allergies.split(",");
            allergies.forEach((allergy) => {
                tags.push({ name: allergy, type: 'allergies' })
            });
        }

        if (props.dietary) {
            const dietaries = props.dietary.split(",");
            dietaries.forEach((dietary) => {
                tags.push({ name: dietary, type: 'dietary' })
            });
        }

        if (props.occasion) {
            const occasions = props.occasion.split(",");
            occasions.forEach((occasion) => {
                tags.push({ name: occasion, type: 'occasion' })
            });
        }

        if (tags.length) {
            return (<Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><TagIcon /></i>{t("tags")}:</span><span>{renderTag(tags)}</span></Typography>);
        } else {
            return ("");
        }
    }

    const renderTag = (tags) => {
        return (tags.map((tag, index) => {
            return (<Chip
                key={index}
                style={getTagColor(tag.name, tag.type)}
                label={tag.name}
            />);
        }));
    }

    function getTagColor(value, type) {
        let result = [];
        result = tags[type].filter((element) => {
            return element.name.trim() === value.trim();
        });

        if (result.length) {
            return {
                backgroundColor: result[0].color,
                color: '#fff'
            }
        }
        return {}
    }

    return (
        <div className='detailContainer'>
            <LayoutItem xs={12}>
                <Typography variant="subtitle1" gutterBottom style={style}><strong style={style}>{props.name}</strong>{t('s')} {props.description}</Typography>
                <Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><Calendar /></i>{t("date_and_time")}:</span><strong style={style}>{moment(props.date_time).locale(defaultLang).format('ddd DD MMM YYYY HH:mm')}</strong></Typography>
                <Typography variant="subtitle1" gutterBottom style={style} className="guest-count"><span className='reservation-item'><i className="icon"><PeopleIcon /></i>{t("number_of_guests")}:</span> <strong style={style}>{props.persons}</strong></Typography>
                {props.referenceCode && <Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><ReferenceIcon /></i>{t("reference_nb")}:</span> <strong style={style}>{props.referenceCode}</strong></Typography>}
                {props.type && props.type === 'join_waitlist' ? '' : totalDisplay()}
                {displayTags()}
                {!!props.widget && <Typography variant="subtitle1" gutterBottom style={style}><span className='reservation-item'><i className="icon"><NotesIcon /></i>{t("notes_requests")}:</span><span style={style}>{props.widget}</span></Typography>}
            </LayoutItem>
            {/* {previewMode &&
                <LayoutItem xs={12} className='reservationAction'>
                    <Typography variant="subtitle1" gutterBottom style={style}><i className="icon"><CalendarIcon /></i>{t("add_to_calendar")}</Typography>
                    <Typography variant="subtitle1" gutterBottom style={style}><i className="icon"><PdfIcon /></i>{t("download_as_pdf")}</Typography>
                </LayoutItem>
            } */}
        </div>
    );
}