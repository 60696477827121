import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import { updateProperty } from "./../../features/store/storeSlice";
import "./../../assets/scss/Section.scss";

export default function Section(props) {
  const { t } = useTranslation();
  const { theme, preferredTables, reservation } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const defaultBoxColor = {
    backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : '',
    opacity: theme.defaultBoxOpacity ? theme.defaultBoxOpacity : '',
    color: theme.calendarLinkColor ? theme.calendarLinkColor : '',
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : '',
  };
  const { innerWidth: width, innerHeight: height } = window;

  const selectedStyle = {
    color: theme.calendarSelectedColor ? theme.calendarSelectedColor : '',
    backgroundColor: theme.calendarSelectedAreaColor ? theme.calendarSelectedAreaColor : '',
    opacity: theme.selectedColorOpacity ? theme.selectedColorOpacity : ''
  };

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const style = { ...defaultBoxColor, ...selectedStyle };

  useEffect(() => {
    if(reservation.preferredTable && (reservation.preferredTable==='Any section' || reservation.preferredTable==='undefined') ){
      let preferredTablesFiltered=preferredTables.filter((item) => !item.is_blocked);
      if (reservation.section.allow_any_section == 0 && preferredTablesFiltered.length >= 1) {
        dispatch(updateProperty({ key: "preferredTable", value: preferredTablesFiltered[0]['name'] }));
        dispatch(updateProperty({ key: "section_id", value: preferredTablesFiltered[0]['id'] }));
        props.handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, preferredTablesFiltered[0]['id'],0);
      } else {
        dispatch(updateProperty({ key: "preferredTable", value: 'Any section' }));
        dispatch(updateProperty({ key: "sectionId", value: 0 }));
        props.handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, 0,0);
      }
    }
  }, [preferredTables]);

  const chooseSection = (section) => {
    dispatch(updateProperty({ key: "preferredTable", value: section.name }));
    dispatch(updateProperty({ key: "section_id", value: section.id }));
    props.handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, section.id || 0,0);
  }
  
  return (
    <LayoutContainer spacing={1} justifyContent="flex-start" alignItems="center" className="section-content">
      <LayoutItem xs={12} className="block-heading">
        <Typography
          variant="h5"
          display="block"
          align="center"
          className="sectionTitle margin-b-0"
        >
          <div
            style={colorDesign}>
            {t("preferred_available_section")}
          </div>
        </Typography>
      </LayoutItem>
      {reservation.section.allow_any_section == 1 &&
        <LayoutItem xs={12} md={4} sm={6} className="preferred-section">
          <Button
            variant="contained"
            onClick={(e) => chooseSection({ 'name': 'Any section' })}
            className={(reservation.preferredTable === 'Any section') ? "selected" : " "}
            style={(reservation.preferredTable === 'Any section') ? style : defaultBoxColor}
            fullWidth
          >
            {t('any_section')}
          </Button>
        </LayoutItem>
      }

      {preferredTables.map((section, key) => {
        return (
          !section.is_blocked && <LayoutItem xs={12} md={4} sm={6} className="preferred-section" key={`section-${key}`} style={width> 600 && (key+2)%3===0?{paddingRight: '0px'}:{}}>
            <Button
              variant="contained"
              onClick={(e) => chooseSection(section)}
              className={(reservation.preferredTable === section.name) ? "selected" : " "}
              style={(reservation.preferredTable === section.name) ? style : defaultBoxColor}
              fullWidth
            >
              {section.name}
            </Button>
          </LayoutItem>)
      })}
    </LayoutContainer>
  );
}